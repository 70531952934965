import React from 'react'
import '../styles/text.css'


function CancellationTerms(){
    return(
        <>
        <div className='cancellationterms'>
                    <span > <h5>Terminos de cancelacion</h5>Lorem ipsum dolor sit amet consectetur adipiscing elit suscipit fames egestas risus taciti 
                            rhoncus, sapien morbi aenean vivamus ac porttitor convallis cursus pretium tristique magnis. 
                            Taciti malesuada odio lectus vestibulum vivamus himenaeos sit amet consectetur adipiscing elit suscipit fames egestas risus taciti 
                            rhoncus, sapien morbi aenean vivamus ac porttitor convallis cursus pretium tristique magnis. 
                            Taciti malesuada odio lectus vestibulum vivamus himenaeos. sit amet consectetur adipiscing elit suscipit fames egestas risus taciti 
                            rhoncus, sapien morbi aenean vivamus ac porttitor convallis cursus pretium tristique magnis. 
                            Taciti malesuada odio lectus vestibulum vivamus himenaeos.. </span>
                           
        </div>
    
        </>
      
    )
}
export default CancellationTerms;