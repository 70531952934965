import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import imagen from "../image/menu.svg";
import Modal from "react-bootstrap/Modal";
import "../pages/hotel/hotel.css";
import "../pages/restaurant/restaurant.css";
import "../pages/buceo/buceo.css";
import "../pages/toures/toures.css";
import Login from "./login";
import { useNavigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
//redux>
import { useSelector, useDispatch } from "react-redux";
import { setUser, resetUser } from "../store/slices/user";
//redux<

function Global_NavBar({ companys, microsites, theme }) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user); //userState.value.id
  const [microsite, setMicrosite] = useState(null);
  const [company, setCompany] = useState(null);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  // Login----------------------------------------
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  useEffect(() => {
    setMicrosite(microsites);
    setCompany(companys);
  }, []);

  const logout = () => {
    Swal.fire({
      title: '¿Desea cerar sesion?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Cerrar!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(resetUser());
        sessionStorage.removeItem("_msSession");
        sessionStorage.removeItem("_JWT");
      }
    })
  };

  const userReservation = () => {
    navigate('/misReservas')
  }

  if (!userState) {
    return (<h1>Loading....</h1>)
  } else {
    return (
      <>

        <nav className={theme}>
          <div className="marca">
            <img src={companys.attributes.avatar.data.attributes.url ? companys.attributes.avatar.data.attributes.url : "Nombre Compañia"}></img>
            <h4>{microsites.attributes.commercialName ? microsites.attributes.commercialName : "name"}</h4>
          </div>
          <div className="confiNavbar">
            {userState.value.id === 0 ?
              <>
                <button onClick={handleShow} className="menu">
                  <img src={imagen}></img>
                </button>
              </> :
              <>
                <p>{userState.value.username ? userState.value.name : ""}</p>
                <button onClick={userReservation} className="btn btn-ligth p-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-check" viewBox="0 0 16 16">
                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" /> 
                  </svg>
                  {" "}Tus reservas 
                </button>
                <button onClick={logout} className="menu btn-sm btn ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                    <path d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"></path>
                    <path d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"></path>
                  </svg>
                  {" "}Salir
                </button>
              </>
            }
          </div>
        </nav>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Inicia sesion
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Login></Login>
            <PrivateRoute user={user}></PrivateRoute>
          </Modal.Body>
        </Modal>

      </>
    );
  }
}

export default Global_NavBar;
