import React, { useState } from "react";
import "../styles/navsites.css";
import Buceo from "../pages/buceo/buceo";
import Hotel from "../pages/hotel/hotel.js";
import DetailBooking from "../pages/room/detailBooking";
import Toures from "../pages/toures/toures.js";
import Restaurant from "../pages/restaurant/restaurante.js";
import Loading from "../pages/loader/Loading";
import Landing from "../pages/landing/landing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MisReservas from "./misReservas";
import PrivateRoute from "./PrivateRoute";
import Login from "./login"
import CreateUser from "./CreateUser";

function Navegation() {
  return (
    <Routes>
      <Route exact path="/Login" element={<Login />}></Route>
      <Route exact path="/tourist/:id" element={<Loading />}></Route>
      <Route exact path="/Hotel" element={<Hotel/>} />
      <Route exact path="/CreateUser" element={<CreateUser/>} />
      <Route exact path="/misReservas" element={
        <PrivateRoute>
          <MisReservas/>
        </PrivateRoute>
      } />
      <Route exact path="/Buceo" element={<Buceo />} />
      <Route exact path="/Toures" element={<Toures />} />
      <Route exact path="/Restaurant" element={<Restaurant />} />
      <Route exact path="/DetailBooking/:id" element={<DetailBooking/>}></Route>
      <Route path="*" element={<Landing></Landing>} />
    </Routes>
  );
}

export default Navegation;
