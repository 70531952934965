import React, {useEffect} from "react";
import "./loading.css";
import { getCompanyById } from "msbookingapiclient";
import {
  BrowserRouter as Router,
  useParams,
  useNavigate
} from "react-router-dom";

function Loading() {
  let navigate = useNavigate();
  let { id } = useParams();

  if(id){
    getCompanyById(id).then((result) => {
      console.log(id);
      let company = result.data;
      sessionStorage.setItem("COMPANY", JSON.stringify(result.data));
      sessionStorage.setItem("idCompany", id);
      let defaultBusiness = company.attributes.businesses.data[0];
      switch (defaultBusiness.attributes.TypeBussines) {
        case "Hotel":
          navigate("/Hotel");
          break;
        case "Buceo":
          navigate("/Buceo");
          break;
        case "Restaurante":
          navigate("/Restaurant");
          break;
        case "Toures":
          navigate("/Toures");
          break;
        default:
          navigate("/landing");
          break;
      }
  }).catch((err)=>{
    navigate("/landing");
  })

  }


  return (
    <>
    <div className="spinner center">
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
      <div className="spinner-blade"></div>
    </div>
    </>
  )
}

export default Loading;
