import React, { useState } from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import { Calendar } from "react-date-range";
import TimeSlots from "../dateRestaurant/TimeSlots";
import Reserva from "../reserva";
import "./DivingReservation.css";

const DivingReservation = ({ divingReservation, setDivingReservation }) => {
  const change = (eventkey) => {};
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = React.useState("06:30 PM");
  const [toDate, setToDate] = React.useState(new Date());
  /*
 onChange={(item) => setDate(item)}
        locale={locales[locale]}
        date={date} */
  return (
    <Container  >
      <Row   style={{ width: "100%" }}>
        <h1>Reserva</h1>
      </Row>
      <Row >
        <Col md>
      <label>Cantidad de reservas:</label>
      <Form.Select>
        <option>Elija la cantidad de personas que desean reservar</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
      </Form.Select>
      </Col>
      <Col md>
      <label>Tipo de Experiencia:</label>
      <Form.Select>
        <option>Seleccione el tipo de experiencia</option>
        <option value="1">Inmersion con minicurso rápido</option>
        <option value="2">Certificacion de buceo</option>
        <option value="3">Ya estoy certificado y deseo solo bucear</option>
      </Form.Select>
      </Col>
      </Row>
      

      <Row >
        <Col md>
        <Calendar
          onChange={(item) => setDate(item)}
          date={date}
          className="calendar-diving"
        />
        </Col>
        <Col md>
        <TimeSlots
          style={{ width: "270px" }}
          intervalSlots={30} //15 cada 15 minutos, 30 cada 30 minutos, 60 cada hora
          disabledHours={["12:00 PM"]}
          hideHours={["00:00 AM"]}
          selectHour={hour}
          onChange={setHour}
          minHour={8} //hora incluida. en formato 24 horas
          maxHour={24} //hora incluida. en formato 24 horas
          setToDate={setToDate} //para establecer la hora seleccionada por el componente
        ></TimeSlots>
        </Col>
        </Row>
      <Reserva/>
    </Container>
  );
};

export default DivingReservation;
