import React , { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Carrusel from './carrusel';
import DescriptionProduct from './descriptionproduct';
import TablePay from './tablePay';
import EpaycoBtn from './epaycoBtn';
import TypePay from './typePay';
import CancellationTerms from'./cancellationTerms';

export default function Reserva() {
  const [show, setShow] = useState(false);
  return (
    <>
    <button className='reserva' onClick={() => setShow(true)}> 
    Reservar
    </button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Custom Modal Styling
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
  
  
    <TablePay/>
    <TypePay/>
   
    <EpaycoBtn 
        amount="105000" 
        keyEpayCo= "53593a034fe673d0afb7093fb196131c"
        taxBase="25" 
        name="Elboton de mi producto"
        description ="descripcion del servicio"
        currency = "cop"
        country= "CO"
        test="true"
        epaycoExternal = "false"
        response ="http://localhost:3000/commitpayment/1245454?epayco=fdsf4343424"
        confirmation = "https://api.macrodatum.com/api/epayco-commit"
        ></EpaycoBtn>
     <CancellationTerms/>
        </Modal.Body>
      </Modal>
      </>
  )
}

