import React from "react";
import "./landing.css";
function landing() {
  return (
    <>
      <nav
        className="navbar navbar-light navbar-expand-md sticky-top navbar-shrink py-3"
        id="mainNav"
        style={{ background: "rgb(255,255,255)", color: "rgb(255,255,255)" }}
      >
        <div className="container">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <span
              className="bs-icon-sm bs-icon-circle bs-icon-primary shadow d-flex justify-content-center align-items-center me-2 bs-icon"
              style={{
                background:
                  'url("https://www.reserva-t.com/static/media/logoempresa.8abc4137.jpeg")',
                borderRadius: 0,
                bsBodyBg: "rgb(255,255,255)",
                backgroundSize: "cover",
              }}
            />
            <span style={{ color: "rgba(0,0,0,0.9)" }}>RESERVA-T</span>
          </a>
          <button
            data-bs-toggle="collapse"
            className="navbar-toggler"
            data-bs-target="#navcol-1"
          >
            <span className="visually-hidden">Toggle navigation</span>
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navcol-1">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item" />
              <li className="nav-item" />
              <li className="nav-item" />
              <li className="nav-item" />
            </ul>
          </div>
        </div>
      </nav>
      {/* 

      <header className="bg-primary-gradient" style={{background: 'rgba(3,29,35,0)', color: 'rgb(255,255,255)'}}>
        <div className="container" style={{width: '100%'}}>
          <div className="row">
            <div className="col-md-12" style={{width: '100%', marginLeft: '-37px', marginRight: '75px', paddingRight: '0px'}}>
              <section data-aos="fade-down">
                <div data-bss-parallax-bg="true" style={{height: '600px', backgroundImage: 'url(https://cdn.bootstrapstudio.io/placeholders/1400x800.png)', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                  <div className="container h-100">
                    <div className="row h-100" style={{background: 'url("./assets/img/buceo.jpg")', backgroundSize: 'cover', paddingLeft: '0px', marginLeft: '-26px', paddingRight: '0px', marginRight: '-88px'}}>
                      <div className="col-md-6 text-center text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
                        <div style={{maxWidth: '350px'}}>
                          <h1 className="text-uppercase fw-bold" style={{marginLeft: '21px', width: '400px'}}>Descubre una aventura nueva&nbsp; cada dia&nbsp;</h1>
                          <p className="my-3" style={{marginLeft: '24px'}}>Tincidunt laoreet leo, adipiscing taciti tempor. Primis senectus sapien, risus donec ad fusce augue interdum.</p><a className="btn btn-primary btn-lg me-2" role="button" href="#" style={{marginLeft: '26px'}}>Explorar</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-end mb-4" data-aos="fade-up-right">
              <div data-aos="fade-up-right" style={{maxWidth: '450px'}}>
                <h2 className="text-uppercase fw-bold">Todo lo que buscas en una sola app&nbsp;</h2>
                <p className="my-3"><br />Reserva hoteles, restaurantes, actividades y toures, todo desde Reserva-T<br /><br /></p>
                <form className="d-flex justify-content-center flex-wrap justify-content-md-start flex-lg-nowrap" method="post">
                  <div className="my-2 me-2" />
                  <div className="my-2"><button className="btn btn-primary shadow" type="submit" style={{background: '#fb8500'}}>Registrarme</button></div>
                </form>
              </div>
            </div>
            <div className="col-md-6 mb-4" data-aos="fade-up-left">
              <div className="p-5 mx-lg-5" style={{background: 'url("./assets/img/blob.svg") center / contain no-repeat'}}><img className="rounded img-fluid shadow w-100 fit-cover" style={{minHeight: '300px'}} src="./assets/img/Captura%20de%20pantalla%202022-10-18%20a%20las%2014.57.02.png" /></div>
            </div>
          </div>
        </div>
      </header>

      <section style={{background: 'rgba(255,255,255,0)'}}>
        <div className="container bg-primary-gradient py-5" style={{background: '#00000000'}}>
          <div className="row">
            <div className="col-md-8 col-xl-6 text-center mx-auto" data-aos="fade-up">
              <p className="fw-bold text-success mb-2" />
              <h3 className="fw-bold" style={{color: 'rgb(255,255,255)'}}>Explora y descubre tu siguiente aventura</h3>
            </div>
          </div>
          <div className="py-5 p-lg-5">
            <div className="row row-cols-1 row-cols-md-2 mx-auto" style={{maxWidth: '900px'}}>
              <div className="col mb-5" data-aos="fade-right">
                <div className="card shadow-sm" />
                <div className="card"><img className="card-img-top w-100 d-block" src="./assets/img/mariscos2.jpg" />
                  <div className="card-body">
                    <h4 className="card-title">Restaurante COQUITO</h4>
                    <p className="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p><button className="btn btn-primary" type="button">RESERVAR</button>
                  </div>
                </div>
              </div>
              <div className="col mb-5" data-aos="fade-left">
                <div className="card shadow-sm" data-aos="fade-left" />
                <div className="card"><img className="card-img-top w-100 d-block" src="./assets/img/buceo2.jpg" />
                  <div className="card-body">
                    <h4 className="card-title">Buceo LADRILLO</h4>
                    <p className="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p><button className="btn btn-primary" type="button">Reservar</button>
                  </div>
                </div>
              </div>
              <div className="col mb-4" data-aos="fade-right">
                <div className="card shadow-sm" />
                <div className="card"><img className="card-img-top w-100 d-block" src="./assets/img/toures3.jpg" />
                  <div className="card-body">
                    <h4 className="card-title">Tour LA PLAYITA</h4>
                    <p className="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p><button className="btn btn-primary" type="button">Reservar</button>
                  </div>
                </div>
              </div>
              <div className="col mb-4" data-aos="fade-left">
                <div className="card shadow-sm" />
                <div className="card"><img className="card-img-top w-100 d-block" src="./assets/img/hotel-gaef2dd8ee_1920.jpg" />
                  <div className="card-body">
                    <h4 className="card-title">Hotel LA MONA</h4>
                    <p className="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p><button className="btn btn-primary" type="button">Reservar</button>
                  </div>
                </div>
              </div>
              <div className="col mb-4" data-aos="fade-right">
                <div className="card shadow-sm" />
                <div className="card"><img className="card-img-top w-100 d-block" src="./assets/img/bedroom-g90957d47c_1920.jpg" />
                  <div className="card-body">
                    <h4 className="card-title">Hotel LA QUINCE</h4>
                    <p className="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p><button className="btn btn-primary" type="button">Reservar</button>
                  </div>
                </div>
              </div>
              <div className="col mb-4" data-aos="fade-left">
                <div className="card shadow-sm" />
                <div className="card"><img className="card-img-top w-100 d-block" src="./assets/img/pexels-darren-lawrence-3417784.jpg" />
                  <div className="card-body">
                    <h4 className="card-title">Hotel PARAISO</h4>
                    <p className="card-text">Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Donec id elit non mi porta gravida at eget metus.</p><button className="btn btn-primary" type="button">Reservar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <footer className="bg-primary-gradient">
        <div className="container py-4 py-lg-5">
          <link
            rel="stylesheet"
            href="https://cdn.lineicons.com/3.0/lineicons.css"
          />
          <section
            className="price_plan_area section_padding_130_80"
            id="pricing"
          >
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8 col-lg-6">
                  {/* Section Heading*/}
                  <div
                    className="section-heading text-center wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <h6>Planes de subscripción</h6>
                    <h3>Trabajemos juntos en tu negocio</h3>
                    <p>
                      Planes para llegar a nuevos clientes y para hacer crecer
                      tu negocio.
                    </p>
                    <div className="line" />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                {/* Single Price Plan Area*/}
                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                  <div
                    className="single_price_plan wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="title">
                      <h3>Plan básico</h3>
                      <p>Servicios base</p>
                      <div className="line" />
                    </div>
                    <div className="price">
                      <h4>$150.000</h4>
                    </div>
                    <div className="description">
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Mantenimiento de imagenes e información de sitio
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Estadisticas de Reservas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        20 Reservas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Acceso a Calendario
                      </p>
                      <p>
                        <i className="lni lni-close" />
                        Chatbot
                      </p>
                    </div>
                    <div className="button">
                      <a className="btn btn-success btn-2" href="#">
                        Suscríbete
                      </a>
                    </div>
                  </div>
                </div>
                {/* Single Price Plan Area*/}
                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                  <div
                    className="single_price_plan active wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    {/* Side Shape*/}
                    <div className="side-shape">
                      <img
                        src="https://bootdey.com/img/popular-pricing.png"
                        alt=""
                      />
                    </div>
                    <div className="title">
                      <span>Popular</span>
                      <h3>Plan Pyme</h3>
                      <p>Para pequeñas y medianas empresas</p>
                      <div className="line" />
                    </div>
                    <div className="price">
                      <h4>$450.000</h4>
                    </div>
                    <div className="description">
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Mantenimiento de imagenes e información de sitios
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Estadísticas de Reservas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        100 Reservas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Acceso a Caledario
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Soporte 24 horas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Pasarela de pagos
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Chatbot
                      </p>
                      <p>
                        <i className="lni lni-close" />
                        Análisis y posicionamiento SEO y SEM de su plataforma
                      </p>
                    </div>
                    <div className="button">
                      <a className="btn btn-warning" href="#">
                        Suscríbete
                      </a>
                    </div>
                  </div>
                </div>
                {/* Single Price Plan Area*/}
                <div className="col-12 col-sm-8 col-md-6 col-lg-4">
                  <div
                    className="single_price_plan wow fadeInUp"
                    data-wow-delay="0.2s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.2s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="title">
                      <h3>Plan Empresarial</h3>
                      <p>Posibilidades ilimitadas</p>
                      <div className="line" />
                    </div>
                    <div className="price">
                      <h4>$1.000.000</h4>
                    </div>
                    <div className="description">
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Mantenimiento de imágenes y de información de sitios
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Estadísticas de sus Reservas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Reservas Ilimitadas
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Acceso a Calendario
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Chatbot
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Análisis para posicionamiento SEO y SEM de su plataforma
                      </p>
                      <p>
                        <i className="lni lni-checkmark-circle" />
                        Pasarela de pagos
                      </p>
                    </div>
                    <div className="button">
                      <a className="btn btn-info" href="#">
                        Suscríbete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="row justify-content-center">
            <div className="col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column item">
              <h3 className="fs-6 fw-bold">Services</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Web design</a>
                </li>
                <li>
                  <a href="#">Development</a>
                </li>
                <li>
                  <a href="#">Hosting</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column item">
              <h3 className="fs-6 fw-bold">About</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Company</a>
                </li>
                <li>
                  <a href="#">Team</a>
                </li>
                <li>
                  <a href="#">Legacy</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4 col-md-3 text-center text-lg-start d-flex flex-column item">
              <h3 className="fs-6 fw-bold">Careers</h3>
              <ul className="list-unstyled">
                <li>
                  <a href="#">Job openings</a>
                </li>
                <li>
                  <a href="#">Employee success</a>
                </li>
                <li>
                  <a href="#">Benefits</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 text-center text-lg-start d-flex flex-column align-items-center order-first align-items-lg-start order-lg-last item social">
              <div className="fw-bold d-flex align-items-center mb-2">
                <a className="navbar-brand d-flex align-items-center" href="/">
                  <span
                    className="bs-icon-sm bs-icon-circle bs-icon-primary shadow d-flex justify-content-center align-items-center me-2 bs-icon"
                    style={{
                      background:
                        'url("https://www.reserva-t.com/static/media/logoempresa.8abc4137.jpeg")',
                      borderRadius: 0,
                      bsBodyBg: "rgb(255,255,255)",
                      backgroundSize: "cover",
                    }}
                  />
                  <span style={{ color: "rgba(0,0,0,0.9)" }}>RESERVA-T</span>
                </a>
              </div>
              <p className="text-muted copyright">
                Sem eleifend donec molestie, integer quisque orci aliquam.
              </p>
            </div>
          </div>
          <hr />
          <div className="text-muted d-flex justify-content-between align-items-center pt-3">
            <p className="mb-0">Copyright © 2022 M&amp;S Consultants SAS</p>
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-facebook"
                >
                  <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                </svg>
              </li>
              <li className="list-inline-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-twitter"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </li>
              <li className="list-inline-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  className="bi bi-instagram"
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                </svg>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default landing;
