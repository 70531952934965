import React, { useState, useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getBookingById } from "msbookingapiclient";
import 'bootstrap/dist/css/bootstrap.min.css';
import Card from 'react-bootstrap/Card';
import CarouselCard from "./cardCarousel";
import '../styles/card.css'



function Example(props) {

    const [card, setCard] = useState({
      booking_hotels:[],

    });
    useEffect(() => {
      getBookingById(props.business.id)
        .then((result) => {
          setCard(result.data);
          
        })
        .catch((error) => {
          console.log(error);
        });
    }, [props.business]);

    let hotelCard = card.booking_hotels.map((item,index)=>{
      let urlRoom="/DetailBooking/" + item.id;

      
  if (card === null ) {
    return <div>Loading...</div>;
  }
      return (
        <div className='card' key={item.uid}>

        <CarouselCard carousel= {item.carousel}/>
        <div className='cardContents'>
        <Card.Title>{item.name}</Card.Title>

            <p fontSize="1 px">{item.description}</p>
            <p>Capacidad adultos:  <b>{item.adultsCapacity}</b> Precio COP: <b>{item.priceAdult}</b><br/>Capacidad niños:  <b>{item.childrenCapacity}</b> Precio COP: <b>{item.priceChildren}</b></p>
         
          
              <Link to={urlRoom} color="white">
                  <button className='reserva' > Consultar</button>
              </Link>
         
      </div>
      </div>
      )
    })

    
    return (
<>
    <div className='containerCard'>
      {hotelCard}
  </div>
        </>
  );
}

export default Example ;