import React, { useEffect, useState } from "react";
import image from '../image/logoempresa.jpeg';
import "../pages/restaurant/restaurant.css";


function Global_Footer({micrositio, theme}) {
  const [microsite, setMicrosite] = useState(null);
  useEffect(() => {
    setMicrosite(micrositio);
  },[]);

  if (microsite === null ) {
    return <div>Loading...</div>;
  }
  return (
    <footer className={theme}>
        <div className='container'>
            <div className='box1'>
              <div className='logo'>
                  <img src={image}></img>
              </div>
              <div className='terms'>
                <p>
                Lorem ipsum dolor sit amet consectetur adipiscing elit, ad bibendum odio himenaeos hac id at, a luctus imperdiet lacinia nibh lobortis. Felis blandit pulvinar lacinia ligula class porta, feugiat mattis varius at tincidunt consequat etiam.
                </p>
              </div>
            </div>
            <div className='box1'>
              <h5>CONTACTO</h5>
              <a>Telefono </a>
              <a>{microsite.attributes.phone1} /  {microsite.attributes.phone2} </a>
              <a >Email </a>
              <a>{microsite.attributes.email1} / {microsite.attributes.email2}</a>
              <a >Direccion</a>
              <a>{microsite.attributes.address}</a>
            </div>
         
            <div className='box1'>
              <h5>REDES SOCIALES</h5>
              <a href={microsite.attributes.facebookUrl}rel="noreferrer" target="_blank">FACEBOOK </a>
              <a href={microsite.attributes.instagramUrl}rel="noreferrer" target="_blank">INSTAGRAM </a>
              <a href={microsite.attributes.twitter}rel="noreferrer" target="_blank">TWITER </a>
           
            </div>

        </div>
      <div className='boxcopyright'>
          <hr></hr>
          <p>Todos los derechos reservados © 2022 <b>M&S</b></p>
      </div>
    </footer>
  )
}

export default Global_Footer;