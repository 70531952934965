import Carousel from 'react-bootstrap/Carousel';
import React from 'react';
import "../pages/room/room.css"

function DetailsCarousel(props) {

  if ( props.carousel.data === null ) {
    return <div>Loading...</div>;
  }else{
   
  return (
    <div className='detailCarousel'>
    <Carousel  fade >
        {props.carousel.data.map((item,index)=>
      <Carousel.Item key={index}>
        <img className='imgCarouselDetail'
          src={item.attributes.url}
          alt={item.attributes.name}
        />
      </Carousel.Item>
      )}
    </Carousel>
    </div>
  );
}
}
export default DetailsCarousel;