import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

function Carrusel(props) {
  const [carousel, setCarousel] = useState([]);

  useEffect(() => {
    if (props.carousel !== null && props.carousel !== undefined) {
      setCarousel(props.carousel.data);
    }
  }, []); 
  
  return (
    <div className="containerSlider">
      <Carousel fade>
        {carousel.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              className="imgSlider"
              src={item.attributes.url}
              alt={item.attributes.name}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
export default Carrusel;
