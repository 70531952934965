import Carousel from 'react-bootstrap/Carousel';
import React from 'react';

function CarouselCard(props) {
  if ( props.carousel === null ) {
    return <div>Loading...</div>;
  }else{
  return (
    <Carousel  fade>
        { props.carousel.map((item,index)=>
      <Carousel.Item key={index}>
        <img
        className='cardCarousel'

          src={item.url}
          alt={item.name}
        />
      </Carousel.Item>
      )}
    </Carousel>
  );
}
}
export default CarouselCard;