import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carrusel from "../../component/carrusel";
import Description from "../../component/description";
import Social_networks from "../../component/socialNetworks";
import DateTime from "../../component/dateTime";
import Location from "../../component/location";
import "./buceo.css";
import Servicios from "../../component/servicios";
import Loading from "../loader/Loading";
import { getBusinessById, getMicroSiteById } from "msbookingapiclient";
import Global_NavBar from "../../component/Global_NavBar";
import Global_Footer from "../../component/Global_Footer";
import Contact from "../../component/contact";
import AboutUs from "../../component/aboutus";
import DivingReservation from "../../component/dateDiving/DivingReservation";

function Buceo() {
  const [company, setCompany] = useState(null);
  const [business, setBusiness] = useState(null);
  const [microsite, setMicrosite] = useState(null);
  const [booking, setBooking] = useState(null);
  const [divingReservation, setDivingReservation] = useState({
    tipoServicio: "",
    cantidad: 1,
    precio: 0,
  });

  useEffect(() => {
    let companySession = JSON.parse(sessionStorage.getItem("COMPANY"));
    setCompany(companySession);
   
    let index = companySession.attributes.businesses.data.findIndex((x) => {
      return x.attributes.TypeBussines === "Buceo";
    });
    let business = companySession.attributes.businesses.data[index];
    getBusinessById(business.id)
      .then((result) => {
        setBusiness(result.data);
    
        getMicroSiteById(result.data.attributes.microsite.data.id)
   
          .then((resultMicrosite) => {
            setMicrosite(resultMicrosite.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (company === null || microsite === null) {
    return <Loading></Loading>;
  }



  return (
    <>
     <Global_NavBar microsites={microsite} companys={company} theme="BuceoNavbar"></Global_NavBar>
     <Social_networks micrositio={microsite} />
     <Container className="containtRestaurant">
       <Row >
          <Col  	md>
              <Carrusel carousel={microsite.attributes.carousel} />
          </Col>
          <Col 	md className="description">
              <Description micrositio={microsite} />
          </Col>
      </Row>
       
      <Row >
        <Col md>
      <AboutUs micrositio={microsite} />
      </Col>
      <Col md>
        <Servicios business={business.attributes.services_bases.data} />
      </Col>
        
      </Row>
        <li className="infContact">
          <Contact micrositio={microsite}/>
          <Location micrositio={microsite} />
    
          </li>
        <Row>
        <DivingReservation
        divingReservation={divingReservation}
        setDivingReservation={setDivingReservation}
      ></DivingReservation>
        </Row>
  </Container>
      <Global_Footer micrositio={microsite} theme="BuceoFooter" />
    </>
  );
}

export default Buceo;
