import React from 'react'
import { Calendar } from 'react-date-range';

const Calendario = () => {
    const handleSelect = (date) => {
        console.log(date); // native Date object
      }

  return (
    <Calendar
        date={new Date()}
        onChange={handleSelect}
      />
  )
}

export default Calendario