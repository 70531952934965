import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { getReservationsByClientMail } from 'msbookingapiclient';
import "../styles/misreservas.css"
import { useSelector, useDispatch } from "react-redux";


function MisReservas() {
  const [reservationByEmail, setReservationByEmail] = useState([]);
  const userState = useSelector((state) => state.user.value);
  useEffect(() => {
    getReservationsByClientMail(userState.email).then((result) => {
      setReservationByEmail(result.data)
    })
  }, [])
debugger
  let reservation = reservationByEmail.map((item, index) =>{
    return(
      <tr key={index}>
      <td  className='text-white'>{item.reservationType}</td>
      <td  className='text-white'>{item.start}</td>
      <td  className='text-white'>{item.end}</td>
      <td  className='text-white'>{item.status}</td>
    </tr>
    )
  })
  

  return (
    <>
      <br/>
      <br/>
      <h2  className='text-white mx-center'>Mis Reservas</h2>
      <Table striped bordered hover>
        <thead className='mt-5 text-white'>
          <tr>
            <th> Tipo de Reserva</th>
            <th>Fecha Inicio</th>
            <th>Fecha Fin</th>
            <th>Estado </th>
          </tr>
        </thead>
        <tbody>
            {reservation}
        </tbody>
      </Table>
    </>
  )
}

export default MisReservas