import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import "../styles/navsites.css";

import { useNavigate, useParams, useLocation  } from "react-router-dom";

function Nav_Sites() {
  const [showHotel, setShowHotel] = useState(false);
  const [showRestaurant, setShowRestaurant] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const [showDiving, setShowDiving] = useState(false);
  const [company, setCompany] = useState(null);
  const [views, setViews] = useState(0);
  let navigate = useNavigate();

  const location = useLocation();

  const incrementViews = useCallback(() => {
    setViews((v) => v + 1);
  }, []);

  useEffect(() => {
    if(company === null)
    {
      return;
    }

    if(location.pathname.includes('/room/')){
      return;
    }

    
    const company = JSON.parse(sessionStorage.getItem("COMPANY"));

    if (company !== null) {
      let businessTypes = company.attributes.businesses.data.map((x) => {
        return x.attributes.TypeBussines;
      });

      setShowHotel(
        businessTypes.findIndex((value) => {
          return value === "Alojamiento";
        }) >= 0
      );
      setShowDiving(
        businessTypes.findIndex((value) => {
          return value === "Buceo";
        }) >= 0
      );
      setShowRestaurant(
        businessTypes.findIndex((value) => {
          return value === "Restaurante";
        }) >= 0
      );
      setShowTour(
        businessTypes.findIndex((value) => {
          return value === "Toures";
        }) >= 0
      );

      let defaultBusiness = company.attributes.businesses.data[0];
      switch (defaultBusiness.attributes.TypeBussines) {
        case "Alojamiento":
          navigate("/Hotel");
          break;
        case "Buceo":
          navigate("/Buceo");
          break;
        case "Restaurante":
          navigate("/Restaurant");
          break;
        case "Toures":
          navigate("/Toures");
          break;
        default:
          navigate("/landing");
          break;
      }
    } else {
      incrementViews();      
    }
  }, [incrementViews]);

  return (
    <nav className="navSites">
      <Link to="/Hotel" className={showHotel ? "" : "hidden"}>
        Hotel
      </Link>
      <Link to="/Restaurant" className={showRestaurant ? "" : "hidden"}>
        Restaurante
      </Link>
      <Link to="/Buceo" className={showDiving ? "" : "hidden"}>
        Buceo
      </Link>
      <Link to="/Toures" className={showTour ? "" : "hidden"}>
        Toures
      </Link>
      {/* <Link to="/Landing">
        Toures
      </Link> */}
    </nav>
  );
}

export default Nav_Sites;
