import React from 'react'
import Table from 'react-bootstrap/Table';

function TablePay() {
  return (
    <Table striped bordered hover>
        <thead>
            <tr>
            <th>Numero de personas</th>
            <th>Precio por persona</th>

            <th>Experiencia</th>
            <th>Total</th>
            </tr>
        </thead>
        <tbody>
              <tr>
              <td>4</td>
              <td>50000</td>
              <td>Certificaion</td>
              <td>200000</td>
              </tr>
        </tbody>
  </Table>
  )
}

export default TablePay