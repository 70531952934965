import React, { useState } from 'react';
import '../styles/CreateUser.css';
import { Button } from 'react-bootstrap';
import { createUser } from "msbookingapiclient";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useSelector, useDispatch } from "react-redux";
import { setUser, resetUser } from "../store/slices/user";


const CreateUser = () => {
    const dispatch = useDispatch();
    const [CreateUserNew, setCreateUserNew] = useState({
        username: "Diego",
        name: "",
        lastname: "",
        phone: "",
        email: "",
        password: "",
        confirmed: "true",
        blocked: "false",
    })

    const userNew = () => {
        createUser(CreateUserNew, "Tourist", "true").then((result) => {
            dispatch(setUser(result));
            console.log(result);
        }).catch((error) => {
            Swal.fire('Este usuario ya esta registrado');
        });
    }
    const onChangeListener = (ev) => {
        switch (ev.target.name) {
            case "name":
                CreateUserNew.name = ev.target.value
                break;
            case "lastname":
                CreateUserNew.lastname = ev.target.value
                break;
            case "phone":
                CreateUserNew.phone = ev.target.value
                break;
            case "email":
                CreateUserNew.email = ev.target.value
                break;
            case "password":
                CreateUserNew.password = ev.target.value
                break;

            default:
                break;
        }
    }
    return (
        <>
            <form>
                <div className='content-crate'>
                    <div className='mt-2'>
                        <label>Nombre: </label>
                        <input onChange={onChangeListener} name="name" className='inputCreate i1' type="text" placeholder='Nombre'></input>
                    </div>
                    <div className='mt-2'>
                        <label>Apellido: </label>
                        <input onChange={onChangeListener} name="lastname" className='inputCreate i1' type="text" placeholder='Apellido'></input>
                    </div>
                    <div className='mt-2'>
                        <label>Telefono: </label>
                        <input onChange={onChangeListener} name="phone" className='inputCreate i1' type="number" placeholder='Telefono'></input>
                    </div>
                    <div className='mt-2'>
                        <label>Correo: </label>
                        <input onChange={onChangeListener} name="email" className='inputCreate i3' type="email" placeholder='user@user.com'></input>
                    </div>
                    <div className='mt-2'>
                        <label>Contraseña: </label>
                        <input onChange={onChangeListener} name="password" className='inputCreate  i4' type="password" placeholder='Contraseña'></input>
                    </div>
                    <Button onClick={userNew} className='mt-3 newUser mb-2' variant="primary"> Crear usuario</Button>
                </div>
            </form>
        </>
    )
}

export default CreateUser