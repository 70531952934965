import React, { useEffect, useState } from "react";
import '../styles/text.css'


function DescriptionProduct({rooms}){

    const [room, setRoom] = useState(null);
    useEffect(() => {
      setRoom(rooms);
     
    },[]);
  
    if (room === null ) {
      return <div>Loading...</div>;
    }
    return(
        <>
        <div className='parrafostyle'>
                        <h1>{room.attributes.name}</h1>
                    <span >{room.attributes.description} </span>
                           
        </div>
    
        </>
      
    )
}
export default DescriptionProduct;