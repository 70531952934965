import { createSlice } from "@reduxjs/toolkit";

let userDefault = {
  id: 0,
  username: "",
  email: "",
  provider: "",
  confirmed: false,
  blocked: false,
  createdAt: null,
  updatedAt: null,
};

const userSession = sessionStorage.getItem("_msSession");

if (userSession !== null) {
  userDefault = JSON.parse(userSession);
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: userDefault,
  },
  reducers: {
    setUser: (state, action) => action.payload,
    resetUser: () => userDefault,
  },
});

export const { setUser, resetUser } = userSlice.actions;
export default userSlice.reducer;
