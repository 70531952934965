import "./hotel.css";
import React, { useEffect, useState } from "react";
import Carrusel from "./carruselHotel";
import Example from "../../component/card";
import Description from "./descriptionHotel";
import Social_networks from "../../component/socialNetworks";
import Servicios from "../../component/servicios";
import Location from "../../component/location";
import Loading from "../loader/Loading";
import Global_NavBar from "../../component/Global_NavBar";
import Contact from "../../component/contact";

import { getBusinessById, getMicroSiteById } from "msbookingapiclient";
import Global_Footer from "../../component/Global_Footer";
function Hotel() {
  const [company, setCompany] = useState(null);
  const [business, setBusiness] = useState(null);
  const [microsite, setMicrosite] = useState(null);
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    let companySession = JSON.parse(sessionStorage.getItem("COMPANY"));
    setCompany(companySession);
    let index = companySession.attributes.businesses.data.findIndex((x) => {
      return x.attributes.TypeBussines === "Alojamiento";
    });
    let business = companySession.attributes.businesses.data[index];
    getBusinessById(business.id)
      .then((result) => {
        setBusiness(result.data);

        getMicroSiteById(result.data.attributes.microsite.data.id)
          .then((resultMicrosite) => {
            setMicrosite(resultMicrosite.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (company === null || microsite === null) {
    return <Loading></Loading>;
  }

  return (
 <>
    <Global_NavBar microsites={microsite} companys={company} theme="HotelNavBar"></Global_NavBar>
          <div className="containthHotel">
            <div className="subcontaintHotel">
             <Carrusel carousel={microsite.attributes.carousel}/>
            </div>
            <Servicios business={business.attributes.services_bases.data} />
              <Description micrositio={microsite} />
              <Social_networks micrositio={microsite} />
              <li className="infContact">
          <Contact micrositio={microsite}/>
          <Location micrositio={microsite} />
    
          </li>
              <Example business={business.attributes.booking.data}  />
         </div>
      <Global_Footer micrositio={microsite} theme="FooterHotel" />
      </>
   
  );
}

export default Hotel;
