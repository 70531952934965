import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navegation from "./component/navigation";
import Nav_Sites from "./component/navsites";
import { ApiEndPoint } from "msbookingapiclient";

//redux persist
import { Provider } from "react-redux";
import store from "./store/index";

function App() {
  sessionStorage.setItem("_APIDOMAIN", "https://api.macrodatum.com/api");
  ApiEndPoint.domain = sessionStorage._APIDOMAIN;
  return (
    <Router>
      <Provider store={store}>
        <Nav_Sites></Nav_Sites>
        <Navegation></Navegation>
      </Provider>
    </Router>
  );
}
export default App;
