import React, { useEffect, useState } from "react";
import { getBusinessServicesById } from "msbookingapiclient";
import '../styles/service.css'


function Servicios(props) {

    let services = props.business.map((item,index)=>{
        return (   
            <div className="uiverse" key={item.attributes.uid}>  
            <img src={item.attributes.icon} height="15px" width="15px" />

         <span>
            { item.attributes.name}
         </span>
         </div>
                )
    });
    

  return (
    <div className='containservices'>
   
            {services}      
    </div>
  );
}

export default Servicios;