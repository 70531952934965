import React, { useEffect, useState } from "react";
import '../../styles/textWhite.css'


function Description({micrositio}){
    const [microsite, setMicrosite] = useState(null);
    useEffect(() => {
      setMicrosite(micrositio);
    },[]);
  
    if (microsite === null ) {
      return <div>Loading...</div>;
    }

  

    return(
        <>
        <div className='parrafostyleHotel'>
                    <span >{microsite.attributes.description} <h5> Acerca de {microsite.attributes.commercialName}</h5> {microsite.attributes.aboutUs}</span>
                           
        </div>
        </>
      
    )
}
export default Description;