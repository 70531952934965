import React, { useEffect, useState } from "react";
import "../styles/socialnetworks.css";
import imagen from "../image/Facebook.png";
import imagen2 from "../image/instagram.png";
import imagen3 from "../image/Whatsapp.png";

function Social_networks({micrositio}) {
  const [microsite, setMicrosite] = useState(null);
  useEffect(() => {
    setMicrosite(micrositio);
  },[]);

  if (microsite === null ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="redes">
        <button>
        <a href={microsite.attributes.facebookUrl}rel="noreferrer" target="_blank">
                  <img src={imagen}></img>
        </a>
        </button>
        <button>
             <a href={microsite.attributes.instagramUrl} rel="noreferrer" target="_blank">
                  <img src={imagen2}></img>
             </a>
        </button>
        <button>
              <a href={microsite.attributes.whatsappPhone} rel="noreferrer" target="_blank">
                  <img src={imagen3}></img>
             </a>
        </button>
    </div>
  );
}

export default Social_networks;
