import React, { useState, useContext } from "react";
import "../styles/login.css";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Authenticate, getCompanyByUserId } from "msbookingapiclient";
import CreateUser from "./CreateUser";

//redux>
import { useSelector, useDispatch } from "react-redux";
import { setUser, resetUser } from "../store/slices/user";
//redux<

function Login() {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // crear un nuevo usuario------------------------------
  const handleShowCreateUser = () => setShowCreateUser(true);
  const [showCreateUser, setShowCreateUser] = useState(false);
  // ---------------------------------------------------------

  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  const handleChange = (ev) => {
    switch (ev.target.name) {
      case "userName":
        values.username = ev.target.value;
        break;
      case "password":
        values.password = ev.target.value;
        break;

      default:
        break;
    }
  };



  const authenticate = async (ev) => {
    Authenticate(values.username, values.password).then((result) => {
      if (result === null) {
        alert("usuario y contraseña incorrectas");
      } else {
        //se guarda en almacen el valor del usuario
        dispatch(setUser(result));
        getCompanyByUserId(result.id).then((result2) => {
          if (result2.data.length > 0) {
            sessionStorage.setItem(
              "MSCOMPANY",
              JSON.stringify(result2.data[0])
            );
            //cerrar ventana de logueo
            //mostrar el linkd e mis reservas.
            //cambiar el nombre en la barra de navegacion con el nombre del usuario.
          } else {
            alert("el usuario no tiene una cuenta asociada");
          }
        });
      }
    });
  };

  const userCreate = () => {
    navigate('/CreateUser')
  }

  return (
    <>

      <div className="containtLogin">
        <TextField
          helperText="Please enter your name"
          name="userName"
          id="demo-helper-text-aligned"
          label="Usuario"
          onChange={handleChange}
        />

        <div>
          <input
            name="password"
            className="password"
            onChange={handleChange}
            type="password"
          ></input>
        </div>

        <a onClick={handleShowCreateUser} className="">Crear usuario</a>

        <button onClick={authenticate} className="reserva">
          {" "}
          Iniciar Session
        </button>
      </div>

      {/* ------------------------Crear usuario ----------------------------------------- */}
      <Modal
        show={showCreateUser}
        onHide={() => setShowCreateUser(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Crear usuario
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <CreateUser></CreateUser>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
