import React, {useRef, useEffect} from 'react'
import '../styles/epaycoBtn.css'


const EpaycoBtn = (props) => {
    const epayContainer = useRef(null);
    useEffect(() => {
        let amount = props.amount
        let key = props.keyEpayCo;
        let taxBase = props.taxBase;
        let name = props.name;
        let description = props.description;
        let currency = props.currency;
        let country = props.country;
        let test = props.test;
        let epaycoExternal = props.epaycoExternal;
        let response = props.response;
        let confirmation = props.confirmation;
        
        const script = document.createElement("script");
        script.src = "https://checkout.epayco.co/checkout.js";
        script.setAttribute('async', true);
        script.setAttribute('data-epayco-key',key);
		script.setAttribute('class','epayco-button position');
		script.setAttribute('data-epayco-amount',amount) 
		script.setAttribute('data-epayco-tax','0.00')
		script.setAttribute('data-epayco-tax-ico','0.00')
		script.setAttribute('data-epayco-tax-base',taxBase) 
		script.setAttribute('data-epayco-name',name)
        script.setAttribute('data-epayco-description',description)
		script.setAttribute('data-epayco-currency',currency)
		script.setAttribute('data-epayco-country',country) 
		script.setAttribute('data-epayco-test',test) 
		script.setAttribute('data-epayco-external',epaycoExternal) 
		script.setAttribute('data-epayco-response',response)
		script.setAttribute('data-epayco-confirmation',confirmation)
        script.setAttribute('data-epayco-button','https://multimedia.epayco.co/dashboard/btns/btn2.png')

        if(epayContainer.current)
            epayContainer.current.replaceWith(script);
      }, [props.amount, props.confirmation, props.country, props.currency, props.description, props.epaycoExternal, props.keyEpayCo, props.name, props.response, props.taxBase, props.test]);



  return (
    <div className='epayco'>
       
        <div id="epayco" ref={epayContainer}>epaycoBtn</div>
       
    </div>    
  )
}

export default EpaycoBtn

