import Rango from "./Rango";
import Calendario from "./Calendario";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import TimeSlots from "./TimeSlots";
import React from "react";

function App() {
  const [hour, setHour] = React.useState("18");
  return (
    <>

      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <h3>Selector de horas {hour}:00</h3>
        <TimeSlots
          disabledHours={["12", "13"]}
          hideHours={[
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "12",
            "06",
            "21",
            "22",
            "23",
          ]}
          selectHour={hour}
          onChange={setHour}
        />
      </div>
    </>
  );
}

export default App;
