import React, { useEffect, useState } from "react";

export default function Contact({micrositio}) {
    const [microsite, setMicrosite] = useState(null);
    useEffect(() => {
      setMicrosite(micrositio);
    });
  
    if (microsite === null ) {
      return <div>Loading...</div>;
    }
  return (
    <div >
                <h3><b>CONTACTO</b></h3>
              <a><b>Telefono </b>{microsite.attributes.phone1} /  {microsite.attributes.phone2} </a>
              <br/>
              <a> <b>Email</b> {microsite.attributes.email1} / {microsite.attributes.email2}</a>
              <br/>
              <a ><b>Direccion</b> {microsite.attributes.address} </a>
     
    </div>
  )
}

