import React from "react";
import "./TimeSlots.css";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";

const TimeSlots = ({
  disabledHours,
  hideHours,
  selectHour,
  onChange,
  intervalSlots,
  minHour,
  maxHour,
  setToDate,
}) => {
  const [selectedHour, setSelectedHour] = React.useState("00");
  const [hours, setHours] = React.useState([]);

  const configureIntervals = () => {
    hours.splice(0, hours.length);
    var x = intervalSlots; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    var ap = [" AM", " PM"]; // AM-PM

    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60);
      var mm = tt % 60;

      var hh12 =
        ap[Math.floor(hh / 12)] === " PM" && hh === 12 ? "12" : hh % 12;

      times[i] =
        ("0" + hh12).slice(-2) +
        ":" +
        ("0" + mm).slice(-2) +
        ap[Math.floor(hh / 12)];
      tt = tt + x;

      if (hh >= minHour && hh < maxHour)
        hours.push({
          hour: times[i],
          selected: false,
          disabled: false,
          hidden: false,
        });
    }
  };

  configureIntervals();

  React.useEffect(() => {
    if (selectHour) {
      hours.map((x) => (x.selected = false));
      const hourItem = hours.findIndex((x) => x.hour === selectHour);
      if (hourItem >= 0) {
        hours[hourItem].selected = true;
        setSelectedHour(hours[hourItem].hour);
      }
    }
  }, [hours, selectedHour]);

  if (disabledHours) {
    hours.map((x) => (x.disabled = false));
    disabledHours.map((x) => {
      const hourItem = hours.findIndex((x1) => x1.hour === x);
      if (hourItem >= 0) hours[hourItem].disabled = true;
    });
  }

  if (hideHours) {
    hours.map((x) => (x.hidden = false));
    hideHours.map((x) => {
      const hourItem = hours.findIndex((x1) => x1.hour === x);
      if (hourItem >= 0) hours[hourItem].hidden = true;
    });
  }

  if (selectHour) {
    hours.map((x) => (x.selected = false));
    const hourItem = hours.findIndex((x) => x.hour === selectHour);
    if (hourItem >= 0) hours[hourItem].selected = true;
  }

  const handleSelectedHour = (item) => {
    if (!item.disabled) {
      setSelectedHour(item.hour);
      hours.map((x) => (x.selected = false));
      const hourItem = hours.findIndex((x) => x.hour === item.hour);
      if (hourItem >= 0) hours[hourItem].selected = true;
      onChange(item.hour);
      const dateSelected = moment(item.hour, "HH:mm a");
      setToDate(dateSelected.toDate());
    }
  };

  return (
    <div className="time-slot-component">
      <Container className="time-slot-container">
        <Row>
          {hours.map((item, index) => {
            if (!item.hidden) {
              return (
                <Col xs={6} key={index}>
                  <div
                    className={
                      item.selected
                        ? "hour-container-selected"
                        : item.disabled
                        ? "hour-container-disabled"
                        : "hour-container"
                    }
                    onClick={() => {
                      handleSelectedHour(item);
                    }}
                  >
                    {item.hour}
                  </div>
                </Col>
              );
            }
          })}
        </Row>
      </Container>

      <p className="selected-hour">Hora: {selectedHour}</p>
    </div>
  );
};

export default TimeSlots;
