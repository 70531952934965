import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import '../styles/date.css'

export default function TypePay() {
   const [age, setAge] = React.useState('');

    const handleChange = (event) => {
      setAge(event.target.value);
    };
    const [age2, setAge2] = React.useState('');
    const handleChange2 = (event) => {
      setAge2(event.target.value);
    };

  return (
    <div className='date'>
          <Box sx={{ minWidth: 170 }}>
              <FormControl fullWidth>
                 <InputLabel id="demo-simple-select-label">Tipo de pago</InputLabel>
                 <Select
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={age}
                     label="Tipo de servicio"
                     onChange={handleChange}
                   >
                    <MenuItem value={6}>Completo</MenuItem>
                    <MenuItem value={12}>Parcial</MenuItem>
         
                  </Select>
              </FormControl>
      </Box>
      <Box sx={{ minWidth: 170 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Metodo de pago</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age2}
                    label="Tipo de servicio"
                    onChange={handleChange2}
                 >
                    <MenuItem value={6}>Pago Electronico</MenuItem>
                    <MenuItem value={12}>En Sitio</MenuItem>
                </Select>
               </FormControl>
       </Box>
     </div>       
  );
}