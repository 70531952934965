import React, { useEffect, useState } from "react";
import '../styles/text.css'


function AboutUs({micrositio}){
    const [microsite, setMicrosite] = useState(null);
    useEffect(() => {
      setMicrosite(micrositio);
    });
  
    if (microsite === null ) {
      return <div>Loading...</div>;
    }

  

    return(
        <>
        <div className='parrafostyle'>
                    <span > <h5> Acerca de {microsite.attributes.commercialName}</h5> {microsite.attributes.aboutUs}</span>
                           
        </div>
        </>
      
    )
}
export default AboutUs;