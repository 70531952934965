import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBookingHotelById } from "msbookingapiclient";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./room.css";
import DetailsCarousel from "../../component/detailsCarousel";
import DescriptionProduct from "../../component/descriptionproduct";
import Servicios from "../../component/servicios";

function DetailBooking() {

  const [room, setRoom] = useState(null);
  let idRoom = useParams();

  useEffect(() => {

    getBookingHotelById(idRoom.id)
      .then((result) => {
        if (result !== undefined) {
          setRoom(result.data);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (room === null) {
    return <div>Loading...</div>;
   
  }else{
console.log(room);
  return (
    
    <Container>
      <Row >    
        <div>nav</div> 
       </Row>
    <Row>
         <Col>
            <DetailsCarousel carousel= {room.attributes.carousel} />
         </Col>
         <Col>
           <DescriptionProduct rooms={room} />
         </Col>
   </Row>
    <Row>
      <Col> 
      
      </Col>
    </Row>
    <Row>
    <div>nav</div> 
    </Row>
  </Container>
 
  )
}
}
export default DetailBooking