import React from "react";
import { Route, Navigate } from "react-router-dom";

//redux>
import { useSelector } from "react-redux";
//redux<

const PrivateRoute = ({children}) => {
  const userState = useSelector((state) => state.user);
  if (userState.value.id === 0){
    return <Navigate to="/Restaurant" redirectTo="/Restaurant"/>
  }
    return children
};

export default PrivateRoute;
