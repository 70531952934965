import React, { useEffect, useState } from "react";
import "./restaurant.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carrusel from "../../component/carrusel";
import Description from "../../component/description";
import Social_networks from "../../component/socialNetworks";
import Servicios from "../../component/servicios";
import Loading from "../loader/Loading";
import { getBusinessById, getMicroSiteById } from "msbookingapiclient";
import Location from "../../component/location";
import { useNavigate } from "react-router-dom";
import Global_NavBar from "../../component/Global_NavBar";
import Global_Footer from "../../component/Global_Footer.js";
import Contact from "../../component/contact";
import AboutUs from "../../component/aboutus";
import App from "../../component/dateRestaurant/App"
import Calendario from "../../component/dateRestaurant/Calendario"
//redux>
import { useSelector } from "react-redux";
import Reserva from "../../component/reserva";



//redux<

function Restaurant() {
  const [company, setCompany] = useState(null);
  const [business, setBusiness] = useState(null);
  const [microsite, setMicrosite] = useState(null);
  let navigate = useNavigate();

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    let companySession = JSON.parse(sessionStorage.getItem("COMPANY"));
    setCompany(companySession);
    if (companySession === null) {
      navigate("/landing");
      return;
    }
    let index = companySession.attributes.businesses.data.findIndex((x) => {
      return x.attributes.TypeBussines === "Restaurante";
    });
    let business = companySession.attributes.businesses.data[index];

    getBusinessById(business.id)
      .then((result) => {
        setBusiness(result.data);

        getMicroSiteById(result.data.attributes.microsite.data.id)
          .then((resultMicrosite) => {
            setMicrosite(resultMicrosite.data.data);
          
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (company === null || microsite === null) {
    return <Loading></Loading>;
  }
 
  return (
<>
      <Global_NavBar
        microsites={microsite}
        companys={company}
        theme="RestaurantNavbar"
      ></Global_NavBar>
 <Social_networks micrositio={microsite} />
  <Container className="containtRestaurant">
       <Row >
          <Col  	md>
              <Carrusel carousel={microsite.attributes.carousel} />
          </Col>
          <Col 	md className="description">
              <Description micrositio={microsite} />
          </Col>
      </Row>
             
      <Row >
        <Col md>
      <AboutUs micrositio={microsite} />
      </Col>
      <Col md>
        <Servicios business={business.attributes.services_bases.data} />
      </Col>
        
      </Row>

        <li className="infContact">
          <Contact micrositio={microsite}/>
          <Location micrositio={microsite} />
    
          </li>
          <Row>  
            <Col md>
                 <Calendario/>
            </Col> 
            <Col md>
           <App></App>
           <Reserva/>
           </Col>
          
      </Row>
  </Container>
      <Global_Footer micrositio={microsite} theme="RestaurantFooter" />
      </>
  );
}

export default Restaurant;
