import React, { useEffect, useState } from "react";
import '../styles/text.css'


function Description({micrositio}){
    const [microsite, setMicrosite] = useState(null);
    useEffect(() => {
      setMicrosite(micrositio);
    },[]);
  
    if (microsite === null ) {
      return <div>Loading...</div>;
    }

  

    return(
        <>
        <div className='parrafostyle'>
                    <span >
                    <h1>{microsite.attributes.commercialName}</h1>
                      {microsite.attributes.description}  </span>
                           
        </div>
        </>
      
    )
}
export default Description;