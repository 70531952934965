import React, { useEffect, useState } from "react";



function Location({micrositio}) {
  const [microsite, setMicrosite] = useState(null);
  useEffect(() => {
    setMicrosite(micrositio);
  });
  console.log("holaaa",microsite);
  if (microsite  === null ) {
    return <div>Loading...</div>;
  }

  return (
    <>
    <div className='location'>
      
   
    <iframe src={microsite.attributes.googlemaps} width="350" height="350"  ></iframe>
    </div>
    </>
  )
}

export default Location;